import wrapper from "../store";
import dynamic from "next/dynamic";
import { getHomeData } from "../store/private/homePageSlice";
import { getAnnouncementsPageData } from "../store/private/announcementsPageSlice";
import { useEffect, useState } from "react";
import Auth from "../shared/auth";
import HomeImages from "../components/Home/homeImages";
import HomeTabCard from "../components/Home/homeTabCard";
import FileCarousel from "../components/fileCarousel"
import Loading from "../shared/partnerComponents/loading/loading";
import { getNavbarData } from "../store/private/headerNavbarSlice";
import { useDispatch } from "react-redux";
import { getCookiesClient } from "../shared/utils/cookie/cookiesClient";
import { getLanguageID } from "../shared/utils/common";
import { announcementsModel } from "../shared/models/mainComponents/announcementsModel";
const ModalGeneral = dynamic(() => import('../shared/partnerComponents/modal/modalGeneral'));
const ModalData = dynamic(() => import('../components/modalData'));
const HeadCustom = dynamic(() => import('../shared/partnerComponents/headCustom'));
const SolutionCategories = dynamic(() => import('../components/Home/solutionCategories'));
const ComplateLineSolutions = dynamic(() => import('../components/Home/complateLineSolutions'));
const WorldMap = dynamic(() => import('../components/Home/worldMap'));
const SubscribeUS = dynamic(() => import('../components/fallowUs'));

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { dispatch, getState } = store;
  await Auth({ ctx, store });
    let item = await getState().homePage;
    let announcementsItem = await getState().announcements;
    let navbarData = await getState().navbar;


    if (item?.loading) {
      await dispatch(getHomeData(ctx)); 
      await dispatch(getNavbarData(ctx)); 
      await dispatch(getAnnouncementsPageData(ctx));
      item = await getState().homePage;
      navbarData = await getState().navbar;
      announcementsItem = await getState().announcements;
    }


    return {
      props: {
        data: item?.data || {},
        announcements: announcementsItem.item || {}
      },
    };
});

const Home = ({ data, announcements }) => {
  const [showModal, setShowModal] = useState(false);
  const [dataAnno, setData] = useState(false);

  const {
    countries, 
    documents, 
    headlines, 
    productCategories, 
    products, 
    seoTag, 
    solutionCategories , 
    widgets
  } = data || null;
  
  useEffect(() => {
    const host = window.location.host;
    let apiHost = null;
    if (
      host === "erseymakina.com" ||
      host === "kurumsal.erseymakina.com" ||
      host === "www.erseymakina.com"
    ) {
      apiHost = "https://kurumsalrouter.erseymakina.com/api/";
    } else {
      apiHost = "https://erseykurumsalrouter.kartega.com/api/";
    }
    getAnnouncementsPageData(apiHost);
  }, []);

  const getAnnouncementsPageData = async (apiHost) => {
    const token = decodeURIComponent(getCookiesClient("token"));
    // let apiHost = "https://kurumsalrouter.erseymakina.com/api/";
    await fetch(`${apiHost}content/GetAnnouncements`, {
      method: "POST", 
      headers: {
        ApplicationKey: "Site",
        AppKey:
          "667f080495499039da1cedabd69a78c3550cd151fbf759a1402df259553a572c04b91249f3cb5ca66f942b4a305e018494cfaead0d4bb65e1029ce3c2919386e",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        highlighted: true,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.hasErrors) {
          setShowModal(true)
          const newData = announcementsModel(data , getLanguageID());
          const filter_Anno = newData.filter((i) => i.highlighted)[0];
          setData(filter_Anno);
        }
      });
  };


  return !data || !announcements ? (
    <Loading innerPageActive/>
  ) : (
    <>
      <HeadCustom metatags={seoTag && seoTag?.metaTags} title={seoTag?.title || "Ersey"} description={seoTag?.description || "Ersey Kurumsal"} />
      <HomeImages headlines={headlines && headlines} /> 
      <HomeTabCard productCategories={products && productCategories} products={products && products} widgets={widgets && widgets}  />  
      <SolutionCategories solutionCategories={solutionCategories && solutionCategories} widgets={widgets && widgets} />
      <ComplateLineSolutions widgets={widgets && widgets} />
      <FileCarousel documents={documents ? documents : null} widgets={widgets && widgets} />
      <WorldMap widgets={widgets &&  widgets} countries={countries && countries} />
      <SubscribeUS title={widgets && widgets[5]?.title} subTitle={widgets && widgets[5]?.subTitle} />
      {
        dataAnno && (
          <ModalGeneral modalStatus={showModal} modalCloseControl={() => setShowModal(false)} closeIconActive={true} whiteModalActive={true} sideClosing={true} closePositonLeft={false} className="announcements">
            <ModalData modalData={dataAnno && dataAnno} />
          </ModalGeneral>
        )
      }
     
    </>
  );
};
export default Home;
